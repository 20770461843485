import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  colors: {
    customGreen: {
      50: "#e0f6eb",
      100: "#b3e4cc",
      200: "#81d2ab",
      300: "#51c18b",
      400: "#25b370",
      500: "#00853E", // primary green
      600: "#006730",
      700: "#004923",
      800: "#002c16",
      900: "#001006",
    },
    customYellow: {
      50: "#f6f6d9",
      100: "#e9e9af",
      200: "#dbdb85",
      300: "#cccc5b",
      400: "#bdbd31",
      500: "#D5E14D", // primary yellow
      600: "#a6a63c",
      700: "#77772b",
      800: "#484819",
      900: "#1a1a08",
    },
    customGray: {
      50: "#f5f5f5",
      100: "#d4d4d4",
      200: "#b4b4b4",
      300: "#939393",
      400: "#737373",
      500: "#525252",
      600: "#414141",
      700: "#313131",
      800: "#212121",
      900: "#111111",
    },
  },
});

export default theme;
