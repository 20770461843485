import React from 'react';
import { Button } from '@chakra-ui/react';

const DownloadCSVObjects = ({ data, filename }) => {
  const downloadCSV = () => {
    // Convert array of objects to CSV string
    if (!data || data.length === 0) {
      console.error("No data to download");
      return;
    }

    const csvHeader = Object.keys(data[0]).join(',');
    const csvRows = data.map(row =>
      Object.values(row).map(value => 
        `"${String(value).replace(/"/g, '""')}"`  // Convert each value to string and escape quotes
      ).join(',')
    );
    const csvString = [csvHeader, ...csvRows].join('\r\n');

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <Button onClick={downloadCSV} colorScheme="blue">
      Download CSV
    </Button>
  );
};

export default DownloadCSVObjects;
