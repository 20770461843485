import * as React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./configs/msalConfig";

// Initialize MSAL
const msalInstance = new PublicClientApplication(msalConfig);

// Attempt to set an active account if one exists
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Listen for sign-in event to set the active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// Get the container to render the app
const container = document.getElementById('root');

// Create a root and initial render
ReactDOM.createRoot(container).render(
  <App msalInstance={msalInstance} />
);
