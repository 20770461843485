// api/providerReportsApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINTS, API_BASE_PR_URL } from '../configs/envConfig';

export const providerReportsApi = createApi({
  reducerPath: 'providerReportsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_PR_URL,
    prepareHeaders: (headers) => {
      headers.set('Accept', 'application/json'); // Default Accept header
      return headers;
    },
    responseHandler: async (response) => {
      const contentType = response.headers.get('Content-Type');
      if (contentType === 'application/pdf') {
        return await response.blob(); // Return response as Blob if it's a PDF
      } else if (contentType && contentType.includes('application/json')) {
        return await response.json(); // Parse response as JSON if it's application/json
      } else {
        return response;
      }
    },
  }),
  endpoints: (builder) => ({
    fetchAndStoreReports: builder.query({
      query: () => API_ENDPOINTS.fetchAndStore,
    }),
    getReports: builder.query({
      query: (providerName) => `${API_ENDPOINTS.singleProvider}${providerName}/`,
      // No specific response type required here; it will be handled by responseHandler
    }),
    getAllGroupedReports: builder.query({
      query: () => API_ENDPOINTS.allReports,
    }),
    getProviderList: builder.query({
      query: () => API_ENDPOINTS.providerList,
    }),
    uploadPdfReport: builder.mutation({
      query: (file) => ({
        url: API_ENDPOINTS.uploadPdfReport,
        method: 'POST',
        body: file,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    }),
    uploadProviders: builder.mutation({
      query: (providers) => ({
        url: API_ENDPOINTS.uploadProviders,
        method: 'POST',
        body: providers,
      }),
    }),
    bulkAddProviders: builder.mutation({
      query: (providers) => ({
        url: API_ENDPOINTS.bulkAddProviders,
        method: 'POST',
        body: providers,
      }),
    }),
    updateProvider: builder.mutation({
      query: ({ providerUuid, data }) => ({
        url: `${API_ENDPOINTS.updateProvider}${providerUuid}/`,
        method: 'PUT',
        body: data,
      }),
    }),
    getReportsAndUpload: builder.query({
      query: (providerName) => `${API_ENDPOINTS.getReportsAndUpload}${providerName}/`,
    }),
    generateAndStoreAllReports: builder.query({
      query: () => API_ENDPOINTS.generateAndStoreAllReports,
    }),
    downloadReport: builder.query({
      query: (reportName) => ({
        url: `${API_ENDPOINTS.downloadReport}${reportName}/`,
        responseType: 'blob',
      }),
    }),
    downloadAllReports: builder.query({
      query: (date) => ({
        url: `${API_ENDPOINTS.downloadAllReports}${date}/`,
        responseHandler: async (response) => {
          const blob = await response.blob();
          return blob;
        },
      }),
    }),
    listReports: builder.query({
      query: ({ providerName, date }) => `${API_ENDPOINTS.listReports}${providerName}/${date}/`,
    }),
  }),
});

export const {
  useFetchAndStoreReportsQuery,
  useGetReportsQuery,
  useLazyGetReportsQuery, // Lazy version of getReports
  useGetAllGroupedReportsQuery,
  useGetProviderListQuery,
  useUploadPdfReportMutation,
  useUploadProvidersMutation,
  useBulkAddProvidersMutation,
  useUpdateProviderMutation,
  useGetReportsAndUploadQuery,
  useGenerateAndStoreAllReportsQuery,
  useLazyGenerateAndStoreAllReportsQuery, // Lazy version of generateAndStoreAllReports
  useDownloadReportQuery,
  useLazyDownloadReportQuery, // Lazy version of downloadReport
  useDownloadAllReportsQuery,
  useLazyDownloadAllReportsQuery, // Lazy version of downloadAllReports
  useListReportsQuery,
  useLazyListReportsQuery, // Lazy version of listReports
} = providerReportsApi;
