import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, Spinner, Textarea, Badge, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import getBadgeColor from '../ServiceFunctions/getBadgeColor';
import DownloadCSVObjects from '../ServiceFunctions/DownloadCsvObjects';
import { API_BASE_URL, API_ENDPOINTS_NEMT_PROVIDER } from '../../../configs/envConfig';

const CombinedAnalysis = ({ cacheKey }) => {
  const [tripIds, setTripIds] = useState('');
  const [fetchedData, setFetchedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const handleInputChange = (e) => {
    setTripIds(e.target.value);
  };

  const performCombinedAnalysis = async () => {
    setIsLoading(true);
    const combinedAnalysisUrl = `${API_ENDPOINTS_NEMT_PROVIDER.combinedAnalysis}/${cacheKey}/`;
    const idsArray = tripIds.split(/\r?\n/).filter(Boolean).map(id => parseInt(id, 10));
  
    try {
      const res = await axios.post(combinedAnalysisUrl, { trip_ids: idsArray });
      console.log('API Response:', res); // Log the whole response object
      console.log('API Data:', res.data);
  
      const data = res.data;
      // Correctly access the filtered_data JSON string and parse it
      const parsedData = JSON.parse(data.data.filtered_data); // This should now correctly parse the JSON string
      console.log('Parsed Data:', parsedData); // Log the parsed data to see its structure
  
      setFetchedData({
        ...data.data,
        filtered_data: parsedData // Set this parsed data for use in CSV
      });
      setResponse({
        status: 'success',
        message: data.message || 'Combined analysis completed successfully',
      });
    } catch (error) {
      console.error("An error occurred while performing combined analysis", error);
      if (error.response) {
        console.log('Error Response Data:', error.response.data);
      }
      setResponse({
        status: 'error',
        message: error.response?.data?.message || "An error occurred during combined analysis.",
      });
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <Box>
      <Textarea
        placeholder="Enter trip IDs separated by commas or spaces"
        value={tripIds}
        onChange={handleInputChange}
        mb={4}
      />
      <Box textAlign="right" mb={4}>
        <Button
          onClick={performCombinedAnalysis}
          isLoading={isLoading}
          disabled={isLoading || !tripIds}
          mb={4}
        >
          Perform Combined Analysis
        </Button>
      </Box>

      {isLoading && <Spinner />}

      {response && (
        <Box mb={4}>
          <Badge colorScheme={getBadgeColor(response.status)}>
            Message: {response.message}
          </Badge>
        </Box>
      )}

      {fetchedData && (
        <>
          <Stat>
            <StatLabel>Trip Count</StatLabel>
            <StatNumber>{fetchedData["Trip Count"]}</StatNumber>
          </Stat>
          <Stat mt={2}>
            <StatLabel>Member Count</StatLabel>
            <StatNumber>{fetchedData["Member Count"]}</StatNumber>
          </Stat>
          <Box mt={4}>
            <DownloadCSVObjects data={fetchedData.filtered_data} filename="combined_analysis.csv" />
          </Box>
        </>
      )}
    </Box>
  );
};

export default CombinedAnalysis;
