import React, { useState } from 'react';
import { VStack, Flex, Spinner, Badge, Input,  Box, Text, List, ListItem, ListIcon } from '@chakra-ui/react';
import { useLazyListReportsQuery } from '../../../redux/providerReportsApi';
import { FaFilePdf } from 'react-icons/fa';
import StyledButtonBase from '../../../GlobalComponents/StyledButtonBase';

const ListReports = () => {
    const [providerName, setProviderName] = useState('');
    const [date, setDate] = useState('');
    const [trigger, { data, error, isLoading }] = useLazyListReportsQuery();

    const handleSubmit = (e) => {
        e.preventDefault();
        trigger({ providerName, date });
    };

    return (
        <Flex justify="center" align="center" direction="column">
            <VStack spacing={4} as="form" onSubmit={handleSubmit}>
                <Input
                    placeholder="Enter Provider Name"
                    value={providerName}
                    onChange={(e) => setProviderName(e.target.value)}
                />
                <Input
                    placeholder="Enter Date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                />
                <StyledButtonBase type="submit" colorScheme="blue" isLoading={isLoading}>
                    List Reports
                </StyledButtonBase>
                {isLoading && <Spinner />}
                {error && <Badge colorScheme="red">Error: {error.message}</Badge>}
                {data && data.data && (
                    <Box w="100%" p={4} borderWidth="1px" borderRadius="lg">
                        <Text fontWeight="bold" mb={2}>Reports:</Text>
                        <List spacing={2}>
                            {data.data.map((report, index) => (
                                <ListItem key={index}>
                                    <ListIcon as={FaFilePdf} color="red.500" />
                                    {report.report_name}
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )}
            </VStack>
        </Flex>
    );
};

export default ListReports;
