import React, { useState } from 'react';
import { Heading, Center, useColorModeValue, Text, Divider, VStack, SimpleGrid, Box, Flex } from '@chakra-ui/react';
import FetchAndStoreReports from './Components/fetchAndStoreReports';
import DownloadReport from './Components/downloadReport';
import ListReports from './Components/listReports';
import GetReports from './Components/getReports';
import GenerateAndStoreAllReports from './Components/GenerateAndStoreAllReports';
import DownloadAllReports from './Components/downloadAllReports';

const ProviderReportsView = () => {
    const cardBg = useColorModeValue('white', 'customGray.600');
    const textColor = useColorModeValue('gray.700', 'white');
    const borderColor = useColorModeValue('gray.300', 'gray.600');

    return (
        <Center flexDirection="column" p={8} minHeight="100vh">
            <VStack spacing={8} w="full" align="stretch">
                
                <Heading mb={3} textAlign="center" color={textColor}>Provider Reports Tools</Heading>
                <Text textAlign="center" color={textColor} mb={6}>
                    Manage and generate reports for providers using the tools below.
                </Text>
                <Divider borderColor={borderColor} mb={6} />

                <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={8}>
                    <Box p={6} bg={cardBg} boxShadow="md" borderRadius="md">
                        <Flex justify="space-between" align="center" mb={3}>
                            <Heading size="md" color={textColor}>Fetch and Store Reports</Heading>
                        </Flex>
                        <Text mb={4} color={textColor}>
                            Pull the latest data for providers. Data will be stored for 2 days.
                        </Text>
                        <Flex justify="flex-end">
                            <FetchAndStoreReports />
                        </Flex>
                    </Box>

                    <Box p={6} bg={cardBg} boxShadow="md" borderRadius="md">
                        <Flex justify="space-between" align="center" mb={3}>
                            <Heading size="md" color={textColor}>Single Provider Report</Heading>
                        </Flex>
                        <Text mb={4} color={textColor}>
                            Generate a report for a single provider by entering their name.
                        </Text>
                        <Flex justify="flex-end">
                            <GetReports />
                        </Flex>
                    </Box>

                    <Box p={6} bg={cardBg} boxShadow="md" borderRadius="md">
                        <Flex justify="space-between" align="center" mb={3}>
                            <Heading size="md" color={textColor}>List Reports for a Provider</Heading>
                        </Flex>
                        <Text mb={4} color={textColor}>
                            View and manage the list of reports available for a specific provider.
                        </Text>
                        <Flex justify="flex-end">
                            <ListReports />
                        </Flex>
                    </Box>

                    <Box p={6} bg={cardBg} boxShadow="md" borderRadius="md">
                        <Flex justify="space-between" align="center" mb={3}>
                            <Heading size="md" color={textColor}>Generate and Store All Reports</Heading>
                        </Flex>
                        <Text mb={4} color={textColor}>
                            Generate and store reports for all providers. This process may take a while.
                        </Text>
                        <Flex justify="flex-end">
                            <GenerateAndStoreAllReports />
                        </Flex>
                    </Box>

                    <Box p={6} bg={cardBg} boxShadow="md" borderRadius="md">
                        <Flex justify="space-between" align="center" mb={3}>
                            <Heading size="md" color={textColor}>Download Report</Heading>
                        </Flex>
                        <Text mb={4} color={textColor}>
                            Download a specific report by entering the report name.
                        </Text>
                        <Flex justify="flex-end">
                            <DownloadReport />
                        </Flex>
                    </Box>

                    <Box p={6} bg={cardBg} boxShadow="md" borderRadius="md">
                        <Flex justify="space-between" align="center" mb={3}>
                            <Heading size="md" color={textColor}>Download All Reports</Heading>
                        </Flex>
                        <Text mb={4} color={textColor}>
                            Download a ZIP file containing all reports for a specific date.
                        </Text>
                        <Flex justify="flex-end">
                            <DownloadAllReports />
                        </Flex>
                    </Box>
                </SimpleGrid>
            
            </VStack>
        </Center>
    );
};

export default ProviderReportsView;
