// data/cardInfo.js
import { FaCar, FaBus, FaBicycle } from 'react-icons/fa';  // Import icons

const cardInfo = [
    {
      appName: 'Nemt Provider Dashboard',
      description: 'Access to Nemt Provider Dashboard.',
      redirectTo: '/nemt-provider-dashboard',
      icon: <FaBus size ={10} />
    },
    {
      appName: 'Nemt Mileage Reimbursement',
      description: 'This Application is for the NEMT Mileage Reimbursement department.',
      redirectTo: '/mileage-reimbursement',
      icon: <FaCar size ={24} />
    },
    {
      appName: 'Credentialing Dashboard',
      description: 'Access to the Credentialing Dashboard',
      redirectTo: '/credentialing-dashboard',
      icon: <FaBicycle size ={24} /> // TODO: Potentially change these to custom icons
      
    },
  ];
  
  export default cardInfo;
  