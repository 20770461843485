import useApiData from './getApiData';
import { API_BASE_URL, API_ENDPOINTS } from '../../../configs/envConfig';

const useSpecialPurpose = (cacheKey) => {
    const specialPurposeUrl = `${API_BASE_URL}${API_ENDPOINTS.specialPurpose}`;
    const { data, isLoading, error, fetchData } = useApiData(specialPurposeUrl);

    const handleSpecialPurpose = () => {
      fetchData(cacheKey);
    };

    return {data, isLoading, error, handleSpecialPurpose};
};

export default useSpecialPurpose;


