import { LogLevel } from "@azure/msal-browser";

let redirectUri_url = "";

if (process.env.NODE_ENV === 'development') {
    redirectUri_url = "http://localhost:3000";
} else {
    // Directly use the placeholder for non-development environments.
    // The entrypoint.sh script will replace this placeholder with the actual URI.
    redirectUri_url = "REACT_APP_REDIRECT_URI_PLACEHOLDER";
}

export const msalConfig = {
    auth: {
        clientId: "ba6e1153-96f8-47d0-8930-b0243bcc1e50",
        authority: "https://login.microsoftonline.com/221f10fe-8638-4291-8c4b-5294e68dc555",
        redirectUri: redirectUri_url,
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: false,

    },
    cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // If you wish to store cache items in cookies as well as browser cache, set this to "true"
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                  return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },

};

export const loginRequest = {
    scopes: ['user.read']
}
