// hooks/useFilterProviders.js
import useApiData from './getApiData';
import {  API_ENDPOINTS_NEMT_PROVIDER } from '../../../configs/envConfig';

const useFilterProviders = (cacheKey) => {
  const filterProvidersUrl = `${API_ENDPOINTS_NEMT_PROVIDER.filterProviders}`;
  const { data, isLoading, error, fetchData } = useApiData(filterProvidersUrl);

  const handleFilterProviders = () => {
    fetchData(cacheKey);
  };

  return { data, isLoading, error, handleFilterProviders };
};

export default useFilterProviders;
