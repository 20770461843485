// CustomCard.js
import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const CustomCard = ({ appName, description, onButtonClick, icon, ...props }) => {
  return (
    <Box
      w="200px"  // Set width
      h="200px"  // Set height
      p={5}
      borderWidth={1}
      borderRadius="md"
      textAlign="center"
      transition="0.3s"
      boxShadow="lg"
      _hover={{ transform: 'scale(1.05)', boxShadow: 'xl' }}
      onClick={onButtonClick}
      cursor="pointer"
      {...props}
    >
      {icon && <Box mb={4}>{icon}</Box>}  {/* Icon with margin */}
      <Heading size="md" mb={2}>{appName}</Heading>  {/* Adjusted margin */}
      <Text>{description}</Text>
    </Box>
  );
};

export default CustomCard;
