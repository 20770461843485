import React from 'react';
import { Button, useColorModeValue } from '@chakra-ui/react';

const StyledButtonBase = ({ children, ...props }) => {
  const buttonBg = useColorModeValue('customGray.700', 'white');
  const textColor = useColorModeValue('white', 'black');
  const borderColor = useColorModeValue('white', 'customGray.700');

  return (
    <Button
      bg={buttonBg}
      color={textColor}
      _hover={{ bg: 'customGreen.300', color: 'black' }} // Custom hover styles
      _active={{ bg: 'customGreen.500' }} // Custom active styles
      borderRadius="md"
      borderColor={borderColor}
      borderWidth={2}
      boxShadow="sm"
      {...props} // Spread additional props
    >
      {children}
    </Button>
  );
};

export default StyledButtonBase;
