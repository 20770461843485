// src/redux/nemtProviderApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const nemtProviderApi = createApi({
  reducerPath: 'nemtProviderApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_BASE_NEMT_URL }),
  endpoints: (builder) => ({
    fetchSomeData: builder.query({
      query: () => 'some-endpoint',
    }),
    // Add other endpoints as needed
  }),
});

export const { useFetchSomeDataQuery } = nemtProviderApi;
