import React from 'react';
import { Box, Button, Spinner, Stat, StatLabel, StatNumber, Badge } from '@chakra-ui/react';
import DownloadCSV from '../ServiceFunctions/DownloadCSV';
import getBadgeColor from '../ServiceFunctions/getBadgeColor';
import useSpecialPurpose from '../Hooks/useSpecialPurpose';


const IsoSpecialPurpose = ({cacheKey}) => {
    const { data, isLoading,error, handleSpecialPurpose} = useSpecialPurpose(cacheKey);

    return (
        <Box mt="auto">
            <Box textAlign="right" mb={4}>
            <Button onClick={handleSpecialPurpose} isLoading={isLoading} disabled={isLoading}>
                Iso Special Purpose
            </Button>
            </Box>
    
            {isLoading && <Spinner m={3} />}
    
            {!isLoading && error && (
            <Box mt={3}>
                <Badge colorScheme="red" px={2} py={1}>
                Error: {error.response?.data.message || error.message || 'Failed to fetch data'}
                </Badge>
            </Box>
            )}
    
            {!isLoading && data && (
            <Box mt={4}>
                <Badge colorScheme={getBadgeColor('success')} px={2} py={1}>
                Message: {data.status || 'Data fetched successfully'}
                </Badge>
                <Stat>
                <StatLabel>Trip Count</StatLabel>
                <StatNumber>{data.data["Trip Count"]}</StatNumber>
                </Stat>
                <Stat mt={2}>
                <StatLabel>Member Count</StatLabel>
                <StatNumber>{data.data["Member Count"]}</StatNumber>
                </Stat>
                <Box mt={4}>
                <DownloadCSV data={data.data["filtered_data"]} filename="Iso_Special_Purpose.csv" />
                </Box>
            </Box>
            )}
        </Box>
        );
};

export default IsoSpecialPurpose;
