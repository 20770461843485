// hooks/useFilterProviders.js
import useApiData from './getApiData';
import { API_ENDPOINTS_NEMT_PROVIDER } from '../../../configs/envConfig';


const useFilterPayable = (cacheKey) => {
    const filterPayableUrl = `${API_ENDPOINTS_NEMT_PROVIDER.filterPayable}`;
    const { data, isLoading, error, fetchData } = useApiData(filterPayableUrl);
  
    const handleFilterPayable = () => {
      fetchData(cacheKey);
    };
  
    return { data, isLoading, error, handleFilterPayable };
  };
  
  export default useFilterPayable;
  