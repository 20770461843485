import React, { useState } from 'react';
import { Box, Button, Spinner, Input, Stack, Badge, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import getBadgeColor from '../ServiceFunctions/getBadgeColor';
import { API_BASE_URL, API_ENDPOINTS_NEMT_PROVIDER } from '../../../configs/envConfig';

const CsvUploader = ({ onUpload, setLoading }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [response, setResponse] = useState({ status: '', message: '', details: '' });

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    setUploading(true);
    setLoading(true);

    //const subscriptionKey = process.env.REACT_APP_APIM_SUBSCRIPTION_KEY;



    try {
      const updateTripsUrl = `${API_ENDPOINTS_NEMT_PROVIDER.csvUpload}/`;

      const response = await fetch(updateTripsUrl, {
        method: 'POST',
        body: formData,
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || 'An error occurred during file upload.');
      }
      // Acess cache key from the 'data' field
      const cacheKey = responseData.data['Cache Key'];
      
      setResponse({
        status: responseData.status || 'success',
        message: responseData.message || 'File uploaded successfully.',
        cacheKey: cacheKey, 
        ...responseData.data,
      });
      onUpload && onUpload(responseData.data);
    } catch (error) {
      setResponse({
        status: 'error',
        message: error.message,
        details: error.details || 'No error details available.',
      });
    } finally {
      setUploading(false);
      setLoading(false);
    }
  };

  return (
    <Box mt="auto">
      <Box textAlign="right"mb={3}>
        <Input placeholder="File path..." isReadOnly={true} value={file ? file.name : ''} />
        <input type="file" accept=".csv" onChange={handleFileChange} style={{ display: 'none' }} id="fileInput" />
        <label htmlFor="fileInput">
          <Button as="span" mt={2}>Select File</Button>
        </label>
      </Box>

      {uploading && <Spinner m={3} />}

      {response.message && (
        <Stack direction='column' spacing={2} mt={3}>
          <Badge colorScheme={getBadgeColor(response.status)} px={2} py={1}>
            {response.message}
          </Badge>
          {response.status === 'success' && (
            <Stat textAlign="left">
              <StatLabel>Trip Count</StatLabel>
              <StatNumber>{response['Count of unique trips']}</StatNumber>
              <StatLabel>Member Count</StatLabel>
              <StatNumber>{response['Count of unique members']}</StatNumber>
            </Stat>
          )}
          {response.status === 'error' && (
            <Box>
              <p>Details: {response.details}</p>
            </Box>
          )}
        </Stack>
      )}

      <Box textAlign="right" mt={4}>
        <Button onClick={handleUpload} isLoading={uploading} disabled={uploading}>
          Upload
        </Button>
      </Box>
    </Box>
  );
};

export default CsvUploader;
