import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, Spinner, Textarea, Badge, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import getBadgeColor from '../ServiceFunctions/getBadgeColor';
import DownloadCSV from '../ServiceFunctions/DownloadCSV';
import { API_BASE_URL, API_ENDPOINTS_NEMT_PROVIDER } from '../../../configs/envConfig';

const UpdateTripStatus = ({ cacheKey }) => {
  const [tripIds, setTripIds] = useState('');
  const [fetchedData, setFetchedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const handleInputChange = (e) => {
    setTripIds(e.target.value);
  };

  const updateTripStatusLogic = async () => {
    setIsLoading(true);
    const updateTripsUrl = `${API_ENDPOINTS_NEMT_PROVIDER.isoInvestigate}/${cacheKey}/`;
    const ids = tripIds.split(/\r?\n/).filter(Boolean).map(Int32Array);
    try {
      const res = await axios.post(updateTripsUrl, { trip_ids: ids });
      setFetchedData(res.data);
      setResponse({
        status: 'success',
        message: res.data.status || 'Trip status updated successfully',
      });
    } catch (error) {
      console.error("An error occurred while updating data", error);
      setResponse({
        status: 'error',
        message: error.response?.data?.message || "An error occurred while updating data.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Textarea
        placeholder="Enter trip IDs separated by commas or spaces"
        value={tripIds}
        onChange={handleInputChange}
        mb={4}
      />
      <Box textAlign="right" mb={4}>
        <Button
          onClick={updateTripStatusLogic}
          isLoading={isLoading}
          disabled={isLoading || !tripIds}
          mb={4}
        >
          Update Trip Status
        </Button>
      </Box>

      {isLoading && <Spinner />}

      {response && (
        <Box mb={4}>
          <Badge colorScheme={getBadgeColor(response.status)}>
            Message: {response.message}
          </Badge>
          {fetchedData && (
            <>
              <Stat>
                <StatLabel>Trip Count</StatLabel>
                <StatNumber>{fetchedData["trip_count"]}</StatNumber>
              </Stat>
              <Stat mt={2}>
                <StatLabel>Member Count</StatLabel>
                <StatNumber>{fetchedData["member_count"]}</StatNumber>
              </Stat>
            </>
          )}
        </Box>
      )}

      {fetchedData && (
        <Box mt={4}>
          <DownloadCSV data={fetchedData.filtered_data} filename="updated_trip_status.csv" />
        </Box>
      )}
    </Box>
  );
};

export default UpdateTripStatus;
