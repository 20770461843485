import React, { useEffect } from 'react';
import { Box, VStack, Heading, Text, Button, Center, Image,Spinner, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
//import { toast } from 'react-toastify';
import { useUser } from '../../Context/User';
import StyledBoxBase from '../../GlobalComponents/StyledBoxBase';
import loginPic from './assets/homeViewTransPic.png'


const LoginView = () => {
  const { isLoggedIn, userInfo, login, isLoading } = useUser(); 
  const navigate = useNavigate();
  const boxColor = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [isLoggedIn, navigate]);

  const handleLogin = () => {
    login();
  };

  // If isLoading is true, show the spinner
  if (isLoading) {
    return (
      <Center h="100vh" w="100%">
        <Spinner />
      </Center>
    );
  }

  return (
    <Box position="relative" h="100vh" w="100%">
      <Image
        src={loginPic}
        position="absolute"
           // Adjust to position the image correctly
        top="0"
        h={{ base: '300px', md: '900px' }}
        w="100%"
        objectFit="cover"
        zIndex="1"
      />
      <Center h="100%" w="100%" zIndex="2" position="relative">
        <StyledBoxBase p={8}>
        <VStack spacing={4} textAlign="center">
          <Heading>Welcome to RideConnection Business Hub</Heading>
          <Text>Please login to continue</Text>
          {isLoggedIn && userInfo ? (
            <>
              <img src={userInfo.avatar} alt={userInfo.name} width="100" height="100" />
              <Text>{userInfo.name}</Text>
            </>
          ) : (
            <>
              <Button onClick={handleLogin}>Login</Button>
              <Button
                variant="link"
                onClick={() => console.log('Forgot Password clicked')}
                _hover={{  // Styling when the button is hovered
                  shadow: "lg",  // Larger shadow on hover
                  transform: "translateY(-2px)",  // Slight upward shift on hover
                }}
              >
                Forgot Password?
              </Button>
            </>
          )}
        </VStack>
      </StyledBoxBase>
    </Center>
    </Box>
  );
};

export default LoginView;
