import React, { useState } from 'react';
import CsvUploader from './components/CsvUploader';
import FilterCompany from './components/FilterCompany';  // Import the new component
import { Box, Heading, Flex,useColorModeValue, Text,  Spinner, Tabs, TabList, Tab, TabPanels, TabPanel, Center, Divider, VStack } from '@chakra-ui/react';
import FilteredDataViewer from './components/FilteredDataView';
import FilterDuplicates from './components/FilterDuplicates';
import UpdateTripStatus from './components/UpdateTripStatus';
import IsoSpecialTripStatusView from './components/IsoSpecialTripStatusView';
import DistributionCleanup from './components/DistributionCleanup';
import IsoSpecialPurpose from './components/IsoSpecialPurpose' ;
import AggregateDataComponent from './components/AggregateDataComponent';
import FilterPayableTrips from './components/FilterPayableTrips';
import CombinedAnalysis from './components/CombinedAnalysis';
import StyledBoxBase from '../../GlobalComponents/StyledBoxBase';
import DataManagementComponent from './components/DataManagementComponent';
import DynamicTable from './components/DynamicTableComponent';
//TODO: I might restructure the hooks calling the api endponts so they are dynamic
// will cut down on the amount of files needed. 

const NemtProviderView = () => {
  const [csvUploaded, setCsvUploaded] = useState(false);
  const [,setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const cardBg = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.700', 'white');
  const [cacheKey, setCacheKey] = useState(null);





  const handleCsvUpload = (responseData) => {
    setCsvUploaded(true);
    setResponse(responseData);
    setCacheKey(responseData['Cache key']);
  };

  
  return (
    <Center flexDirection="column" p={3}> 
        {/* Instructions Card */}
        <VStack spacing={5} w="full" algin="stretch">
        <StyledBoxBase p={5} w="full" h = "auto">          
        <Heading mb={3} textAlign="center">NEMT Provider App Instructions</Heading>
          <Text textAlign='center'> Please upload the CSV file containing the necessary data. Follow the instructions below</Text>
          <Divider mt = '5' border='8px' borderColor ={borderColor} borderRadius='md'></Divider>
          <Text textAlign='left'>
              <li>Select a CSV file by clicking on the 'Upload' button.</li>
              <li>Press Upload.</li>
              <li>Once the csv has been processed a new view will appear. Each tab has different task. </li>
              <li>Each tab has different task. The first tab will run all the analysis and return an excel file </li>
          </Text>
          <Divider my = '10'></Divider>
        </StyledBoxBase>


        {/* CSV Uploader and Response Section */}
        <Flex direction={['column', 'row']} align="start" w="full" spacing={[100, 100]}>
          {/* CSV Uploader Section */}
          <StyledBoxBase flex="1" minHeight='500px' w="full">            
          <Tabs>
              <TabList>
                <Tab>Upload CSV</Tab>

                {/* You can add more tabs here */}
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Text textAlign='left' fontSize="md" mb={3}>Step 1: Upload CSV</Text>
                  <CsvUploader onUpload={handleCsvUpload} setLoading={setLoading} />
                  {/* Show Spinner when uploading */}
                  {loading && <Center><Spinner /></Center>}
                </TabPanel>
                {/* Add more TabPanels here for additional tabs */}
              </TabPanels>
            </Tabs>
          </StyledBoxBase>

          {/* Response Section */}
          <StyledBoxBase flex="1" minHeight='500px'>
        {csvUploaded ? (
          <>
            <Tabs>
              <TabList>
                <Tab>All Analysis</Tab>
                <Tab>Invalid Providers</Tab>
                <Tab>Comp Cancel</Tab>
                <Tab>Duplicates</Tab>
                <Tab>Investigations</Tab>
                <Tab>Problem Trips</Tab>
                <Tab>Distribution Dates</Tab>
                <Tab>Trip Purpose Errors</Tab>
                <Tab>Payable Trips</Tab>
                {/* Add more tabs as needed */}
              </TabList>
              <TabPanels flex="1">
                <TabPanel display="flex" flexDirection="column" flex="1">
                  {/* Use the FilterCompany component */}
                  <CombinedAnalysis cacheKey={cacheKey}/>
                </TabPanel>
                <TabPanel display="flex" flexDirection="column" flex="1">
                  {/* Use the FilterCompany component */}
                  <FilterCompany  cacheKey={cacheKey}/>
                </TabPanel>
                <TabPanel display="flex" flexDirection="column" flex="1">
                    <FilteredDataViewer cacheKey={cacheKey} />
                </TabPanel>
                <TabPanel display="flex" flexDirection="column" flex="1">
                    <FilterDuplicates cacheKey={cacheKey} />
                </TabPanel>
                <TabPanel display="flex" flexDirection="column" flex="1">
                    <UpdateTripStatus cacheKey={cacheKey} />
                </TabPanel>
                <TabPanel display="flex" flexDirection="column" flex="1">
                    <IsoSpecialTripStatusView cacheKey={cacheKey} />
                </TabPanel>
                <TabPanel display="flex" flexDirection="column" flex="1">
                  <DistributionCleanup cacheKey={cacheKey} />
                </TabPanel> 
                <TabPanel display="flex" flexDirection="column" flex="1">
                  <IsoSpecialPurpose cacheKey={cacheKey} />
                </TabPanel>
                <TabPanel display="flex" flexDirection="column" flex="1">
                  <FilterPayableTrips cacheKey={cacheKey} />
                </TabPanel>
                {/* Add more TabPanels as needed */}
              </TabPanels>
            </Tabs>
          </>
        ) : (
              <Text textAlign="left">Once the CSV is uploaded, you can review the details and proceed with the next steps here.</Text>
            )}
          </StyledBoxBase>
        </Flex>
        <StyledBoxBase p={5} w="full" h = "auto">   
          <DataManagementComponent cacheKey={cacheKey}/>
        </StyledBoxBase>
        </VStack>
        
    </Center>
    
  );
};

export default NemtProviderView;