import React from 'react';
import { Box, List, ListItem, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const NavigationDrawer = () => {
  const navigate = useNavigate();  // Instantiate the useNavigate hook
  
  return (
    <Box>
      <List spacing={3}>
        <ListItem>
          <Button variant="ghost" onClick={() => navigate('/home')}>
            Home
          </Button>
        </ListItem>
        <ListItem>
          <Button variant="ghost" onClick={() => navigate('/nemt-provider-dashboard')}>
            Nemt Provider Dashboard
          </Button>
        </ListItem>
        <ListItem>
          <Button variant="ghost" onClick={() => navigate('/credentialing-dashboard')}>
            Credentialing Dashboard
          </Button>
        </ListItem>
        {/* Add more navigation items as needed */}
      </List>
    </Box>
  );
};

export default NavigationDrawer;
