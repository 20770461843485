import React, { useState } from 'react';
import { VStack, Flex, Spinner, Badge, Input, Box, } from '@chakra-ui/react';
import { useLazyGetReportsQuery } from '../../../redux/providerReportsApi';
import ProviderDropdown from './providerDropdown';
import StyledButtonBase from '../../../GlobalComponents/StyledButtonBase';

const GetReports = () => {
    const [providerName, setProviderName] = useState('');
    const [trigger, { data, error, isLoading }] = useLazyGetReportsQuery();
    const [isGeneratingPdf] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        trigger(providerName);
    };

    const handleDownload = () => {
        if (data && data instanceof Blob) {
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${providerName}_report.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    };

    const handlePreview = () => {
        if (data && data instanceof Blob) {
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
        }
    };

    return (
        <VStack spacing={4}>
            <Flex direction="column" align="start" w="100%">
                <Input mb={'10px'} value={providerName} onChange={(e) => setProviderName(e.target.value)} placeholder="Or enter provider name" />
                <ProviderDropdown selectedProvider={providerName} setSelectedProvider={setProviderName} />
            </Flex>
            <Box w="100%">
                <Flex justify="end">
                    <VStack spacing={1}>
                        {isLoading && <Spinner />}
                        {isGeneratingPdf && <Spinner />}
                        {error && <Badge colorScheme="red">{error.message}</Badge>}
                        <StyledButtonBase onClick={handleSubmit}  isLoading={isLoading}>Fetch Report</StyledButtonBase>
                        {data && (
                            <>
                                <StyledButtonBase onClick={handleDownload}  mt={2}>Download PDF</StyledButtonBase>
                                <StyledButtonBase onClick={handlePreview} mt={2}>Preview PDF</StyledButtonBase>
                            </>
                        )}
                    </VStack>
                </Flex>
            </Box>
        </VStack>
    );
};

export default GetReports;
