import React from 'react';
import { useGetProviderListQuery } from '../../../redux/providerReportsApi';

const ProviderDropdown = ({ selectedProvider, setSelectedProvider }) => {
    const { data, error, isLoading } = useGetProviderListQuery();
  
    const handleProviderChange = (event) => {
        setSelectedProvider(event.target.value);
    };
  
    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
  
    return (
        <div>
            <select value={selectedProvider} onChange={handleProviderChange} disabled={!data || !data.data}>
                <option value="">Select a provider</option>
                {data && data.data && data.data.map((provider, index) => (
                    <option key={index} value={provider}>
                        {provider}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default ProviderDropdown;
