//hooks/useSpecialTripStatus.js
import useApiData from './getApiData';
import { API_ENDPOINTS_NEMT_PROVIDER } from '../../../configs/envConfig';


const useSpecialTripStatus = (cacheKey) => {
    const isoSpecialStatusUrl = `${API_ENDPOINTS_NEMT_PROVIDER.isoSpecialStatus}`;
    const { data, isLoading, error, fetchData } = useApiData(isoSpecialStatusUrl);

    const handleIsoSpecialTripStatus = () => {
      fetchData(cacheKey);
    };

    return { data, isLoading, error, handleIsoSpecialTripStatus };

};

export default useSpecialTripStatus;