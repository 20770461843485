import React, { useState } from 'react';
import CombinedAnalysis from './CombinedAnalysis';
import DynamicTable from './DynamicTableComponent';
import { Select } from '@chakra-ui/react';

const DataManagementComponent = ({cacheKey}) => {
  const [selectedApi, setSelectedApi] = useState('');
  const [data, setData] = useState([]);

  const handleApiSelectionChange = (event) => {
    setSelectedApi(event.target.value);
    // Reset data when changing selection
    setData([]);
  };

  // Callback function to receive data from the CombinedAnalysis component
  const handleDataFetched = (fetchedData) => {
    setData(fetchedData);
  };

  return (
    <div>
      <Select placeholder="Select API" onChange={handleApiSelectionChange}>
        <option value="combinedAnalysis">Combined Analysis</option>
        {/* Add more options for other APIs or functionalities */}
      </Select>

      {selectedApi === 'combinedAnalysis' && (
        <CombinedAnalysis onDataFetched={handleDataFetched} cacheKey={cacheKey} mode={"table-view"} />
      )}
      {/* Render components for other APIs based on selectedApi */}

      <DynamicTable initialData={data} />
    </div>
  );
};

export default DataManagementComponent;
