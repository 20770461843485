import React from 'react';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import theme from './themes/RC_Colors';
import MainLayout from './views/layouts/MainLayout';
import AppRouter from './Routing/AppRouter';
import NavigationDrawer from './views/layouts/MainLayout/NavigationDrawer';
import { UserProvider } from './Context/User';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux'; // Corrected import
import store from './redux/store'; // Import your store

// Create a new QueryClient instance
const queryClient = new QueryClient();

function App({ msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <UserProvider msalInstance={msalInstance}>
        <Provider store={store}> {/* Wrap your app with the Redux Provider */}
          <ChakraProvider theme={theme}>
            <CSSReset />
            <Router>
              <QueryClientProvider client={queryClient}>
                <MainLayout drawer={<NavigationDrawer />}>
                  <AppRouter />
                </MainLayout>
                <ToastContainer />
              </QueryClientProvider>
            </Router>
          </ChakraProvider>
        </Provider>
      </UserProvider>
    </MsalProvider>
  );
}

export default App;