import React from 'react';
import { Button} from '@chakra-ui/react';

const DownloadCSV = ({ data, filename }) => {
  const downloadCSV = () => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Button onClick={downloadCSV}>
      Download CSV
    </Button>
  );
};

export default DownloadCSV;
