import React from 'react';
import { Box, Heading, VStack, Button, Flex, Spacer, Grid, GridItem, useColorModeValue, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const DashboardTemplate = ({ title, description, sections, navigationItems }) => {
  const navigate = useNavigate();
  const bg = useColorModeValue('gray.100', 'gray.900');
  const cardBg = useColorModeValue('white', 'customGray.600');
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.300', 'gray.600');

  return (
    <Box p={5} bg={bg} minHeight="100vh">
      <Flex mb={5} alignItems="center" bg={cardBg} p={4} borderRadius="md" boxShadow="md">
        <VStack align="start" spacing={2}>
          <Heading size="lg" color={textColor}>{title}</Heading>
          <Text color={textColor} fontSize="md">{description}</Text>
        </VStack>
        <Spacer />
        {/* Add any additional header elements here */}
      </Flex>
      <Flex>
        <Box w="20%" p={5} bg={cardBg} borderRight="1px solid" borderColor={borderColor} borderRadius="md" boxShadow="md">
          <VStack spacing={4} align="stretch">
            {navigationItems.map((item, index) => (
              <Button key={index} variant="ghost" onClick={() => navigate(item.link)} color={textColor}>
                {item.label}
              </Button>
            ))}
          </VStack>
        </Box>
        <Box w="80%" p={5}>
          <Grid templateColumns="repeat(3, 1fr)" gap={6}>
            {sections.map((section, index) => (
              <GridItem key={index} w="100%" h="200px" bg={cardBg} borderRadius="md" boxShadow="md" p={4}>
                <Heading size="md" color={textColor}>{section.title}</Heading>
                {section.content}
              </GridItem>
            ))}
          </Grid>
        </Box>
      </Flex>
    </Box>
  );
};

export default DashboardTemplate;
