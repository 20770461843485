import React from 'react';
import {
  Box,
  Flex,
  Text,
  Select,
  useColorModeValue,
  Heading,
  FormControl,
  FormLabel,
  Center,
  Divider,
} from '@chakra-ui/react';
import DarkModeToggle from '../layouts/MainLayout/DarkModeToggle';

import StyledBoxBase from '../../GlobalComponents/StyledBoxBase';

const Settings = () => {
  const borderColor = useColorModeValue('white', 'gray.600');

  return (
    <Center>
      
        {/* Settings Card */}
    <StyledBoxBase p={5} w="full" h = "auto">
          <Heading mb={3} textAlign="center">Settings</Heading>
          <Divider mt='5' borderColor={borderColor} borderRadius='md'></Divider>

          {/* Dark Mode Toggle */}
          <Flex alignItems="center" justifyContent="space-between" mt={5} mb={5}>
            <Text fontSize="lg">Dark Mode</Text>
            <DarkModeToggle />
          </Flex>

          <Divider mb={5} />

       
        </StyledBoxBase>
     
    </Center>
  );
};

export default Settings;
