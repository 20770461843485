import { useState, useEffect } from 'react';

const useDynamicTable = (initialData) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const updatedRows = initialData.map((row, index) => ({
      id: row.id || index,
      ...row,
    }));
    setRows(updatedRows);

    if (initialData.length > 0) {
      const dynamicColumns = Object.keys(initialData[0]).map(key => ({
        key,
        name: key.charAt(0).toUpperCase() + key.slice(1),
        editable: true,
      }));
      setColumns(dynamicColumns);
    }
  }, [initialData]);

  return { rows, columns, setRows };
};

export default useDynamicTable;
