import React, { useState, useEffect } from 'react';
import { VStack, Flex, Spinner, Badge, Input } from '@chakra-ui/react';
import { useLazyDownloadAllReportsQuery } from '../../../redux/providerReportsApi';
import StyledButtonBase from '../../../GlobalComponents/StyledButtonBase';

const DownloadAllReports = () => {
    const [date, setDate] = useState('');
    const [isValidDate, setIsValidDate] = useState(true);
    const [trigger, { data, error, isLoading }] = useLazyDownloadAllReportsQuery();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateDate(date)) {
            trigger(date);
        } else {
            setIsValidDate(false);
        }
    };

    const handleDownload = () => {
        if (data && data instanceof Blob) {
            const blob = new Blob([data], { type: 'application/zip' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `reports_${date}.zip`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    };

    const validateDate = (date) => {
        const regex = /^\d{8}$/;
        return regex.test(date);
    };

    const handleChange = (e) => {
        setDate(e.target.value);
        if (!isValidDate) {
            setIsValidDate(true);
        }
    };

    useEffect(() => {
        if (data) {
            handleDownload();
        }
    }, [data]);

    return (
        <Flex justify="center" align="center" direction="column">
            <VStack spacing={4} as="form" onSubmit={handleSubmit}>
                <Input
                    placeholder="Enter Date (YYYYMMDD)"
                    value={date}
                    onChange={handleChange}
                    isInvalid={!isValidDate}
                    errorBorderColor="red.300"
                />
                {!isValidDate && <Badge colorScheme="red">Invalid date format. Please use YYYYMMDD.</Badge>}
                <StyledButtonBase onClick={handleSubmit} isLoading={isLoading}>
                    Download ZIP
                </StyledButtonBase>
                {isLoading && <Spinner />}
                {error && <Badge colorScheme="red">Error: {error.message}</Badge>}
            </VStack>
        </Flex>
    );
};

export default DownloadAllReports;