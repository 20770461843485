import useApiData from './getApiData';
import { API_BASE_URL, API_ENDPOINTS_NEMT_PROVIDER  } from '../../../configs/envConfig';

const useDistributionCleanup = (cacheKey) => {
    const distributionCleanupUrl = `${API_ENDPOINTS_NEMT_PROVIDER.distributionCleanup}`;
    const { data, isLoading, error, fetchData } = useApiData(distributionCleanupUrl);
    
    const handleDistributionCleanup = () => {
      fetchData(cacheKey);
    };

    return { data, isLoading, error, handleDistributionCleanup};

};

export default useDistributionCleanup;
