import React from 'react';
import { Box, List, ListItem, Button } from '@chakra-ui/react';
import { useUser } from '../../../Context/User';
import { useNavigate } from 'react-router-dom';
  
const ProfileOptionsDrawer = () => {
  const navigate = useNavigate();  // Instantiate the useNavigate hook
  const { logout } = useUser();
  return (
    <Box>
      <List spacing={3}>
        <ListItem>
          <Button variant="ghost" onClick={() => navigate('/settings')}>
            Settings
          </Button>
        </ListItem>
        <ListItem>
          <Button variant="ghost" onClick={logout}>
            Logout
          </Button>
        </ListItem>
        {/* Add more profile option items as needed */}
      </List>
    </Box>
  );
};

export default ProfileOptionsDrawer;
