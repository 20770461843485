// config.js
import endpoints from './endpoints';

let API_BASE_URL;
let API_BASE_PR_URL;

const environment = process.env.NODE_ENV || 'development';

switch (environment) {
  case 'production':
    API_BASE_URL = process.env.REACT_APP_API_BASE_NEMT_URL;
    API_BASE_PR_URL = process.env.REACT_APP_API_BASE_PR_URL;
    break;
  case 'test':
    API_BASE_URL = process.env.REACT_APP_API_BASE_TEST_URL;
    API_BASE_PR_URL = process.env.REACT_APP_API_BASE_PR_URL;
    break;
  case 'development':
  default:
    API_BASE_URL = process.env.REACT_APP_DEV_API_BASE_URL_DEVELOPMENT;
    API_BASE_PR_URL = process.env.REACT_APP_PR_DEV_API_BASE_URL;
    break;
}

const API_ENDPOINTS = Object.keys(endpoints).reduce((acc, key) => {
  acc[key] = `${API_BASE_PR_URL}${endpoints[key]}`;
  return acc;
}, {});

const API_ENDPOINTS_NEMT_PROVIDER = Object.keys(endpoints).reduce((acc, key) => {
  acc[key] = `${API_BASE_URL}${endpoints[key]}`;
  return acc;
}, {});

export { API_BASE_URL, API_ENDPOINTS, API_BASE_PR_URL, API_ENDPOINTS_NEMT_PROVIDER };
