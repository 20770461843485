

const getBadgeColor = (status) => {
    switch (status) {
        case'success':
            return 'green';
        case 'error':
            return'red';
        default:
            return 'gray';
    }
};

export default getBadgeColor;