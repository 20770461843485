//hooks/useFilterDuplicates.js
import useApiData from './getApiData';
import { API_ENDPOINTS_NEMT_PROVIDER } from '../../../configs/envConfig';

const useFilteredDataView = (cacheKey) => {
    const filterCancelUrl = `${API_ENDPOINTS_NEMT_PROVIDER.filterCancel}`;
    const { data, isLoading, error, fetchData } = useApiData(filterCancelUrl);
    
    const handleFetchCancelledTrips = () => {
      fetchData(cacheKey);
    };

    return { data, isLoading, error, handleFetchCancelledTrips };

};

export default useFilteredDataView;
