import React, { createContext, useContext, useState, useEffect , useCallback} from 'react';
import { loginRequest } from '../../configs/msalConfig';
import { EventType } from '@azure/msal-browser';
import  {jwtDecode} from 'jwt-decode';  

const UserContext = createContext();

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export const UserProvider = ({ children, msalInstance }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const updateUserInfoFromToken = (account, tokenResponse) => {
    const decodedToken = jwtDecode(tokenResponse.idToken);
    setUserInfo({
      name: decodedToken.name || account.name,
      username: decodedToken.preferred_username || account.username,
      roles: decodedToken.roles || [],
    });
  };

  const checkAndAcquireToken = useCallback((account) => {
    msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    }).then(response => {
      updateUserInfoFromToken(account, response);
    }).catch(err => {
      console.error('Failed to acquire token silently', err);
      setError(err);
      setIsLoggedIn(false);
    });
  }, [msalInstance]); // Add any other dependencies if they are used inside checkAndAcquireToken
  

  useEffect(() => {
    const localUserInfo = localStorage.getItem('userInfo');
    if (localUserInfo) {
      const parsedUserInfo = JSON.parse(localUserInfo);
      setUserInfo(parsedUserInfo);
      setIsLoggedIn(true);
    } else {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        checkAndAcquireToken(accounts[0]);
      }
    }
    setIsLoading(false);

    const callbackId = msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        const account = event.payload.account;
        checkAndAcquireToken(account);
      } else if (event.eventType === EventType.LOGIN_FAILURE ||
                 event.eventType === EventType.LOGIN_ERROR ||
                 event.eventType === EventType.LOGOUT_SUCCESS) {
        setIsLoggedIn(false);
        setUserInfo(null);
        setIsLoading(false);
        localStorage.removeItem('userInfo');
      }
    });

    return () => {
      msalInstance.removeEventCallback(callbackId);
    };
  }, [msalInstance,checkAndAcquireToken]);

  useEffect(() => {
    if (userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      localStorage.setItem('isLoggedIn', isLoggedIn.toString());  // Persist isLoggedIn state
    }
  }, [userInfo, isLoggedIn]);

  const performLogin = () => {
    setIsLoading(true);
    msalInstance.loginPopup(loginRequest)
      .then(response => {
        // Update the user info with the response data
        updateUserInfoFromToken(response.account, response);
  
        // Set isLoggedIn to true as the login is successful
        setIsLoggedIn(true);
  
      })
      .catch(err => {
        // Handle any errors that occur during login
        console.error('Login failed: ', err);
        setError(err);
      })
      .finally(() => {
        // Set isLoading to false as the login process is complete
        setIsLoading(false);
      });
  };
  

  const login = () => {
    performLogin();
  };

  const logout = () => {
    msalInstance.logoutRedirect().then(() => {
      setIsLoggedIn(false);
      setUserInfo(null);
      localStorage.removeItem('userInfo');
    }).catch(err => {
      console.error('Logout failed: ', err);
      setError(err);
    });
  };

  return (
    <UserContext.Provider value={{ isLoggedIn, userInfo, login, logout, error, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
