// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import { nemtProviderApi } from './nemtProviderApi';
import { providerReportsApi } from './providerReportsApi';

const store = configureStore({
  reducer: {
    [nemtProviderApi.reducerPath]: nemtProviderApi.reducer,
    [providerReportsApi.reducerPath]: providerReportsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false,}).concat(nemtProviderApi.middleware, providerReportsApi.middleware),
});

export default store;
