import React from 'react';
import { Box, Heading, Text, Grid, Image, useColorModeValue, Center, Flex, VStack, Divider } from '@chakra-ui/react';
import CustomCard from './components/CustomCard';
import cardInfo from './data/cardInfo';
import headPic from './assets/layered-peaks-haikei.svg';
import { useNavigate } from 'react-router-dom';

const HomeView = () => {
  const cardBg = useColorModeValue('white', 'customGray.700');
  const textColor = useColorModeValue('customGray.700', 'white');
  const borderColor = useColorModeValue('customGray.700', 'white');
  const textBoxColor = useColorModeValue('white', 'customGray.700');
  const navigate = useNavigate();

  return (
    <Center flexDirection="column" p={3}>
      {/* Full-Width Banner Image */}
      <Image src={headPic} alt="Ride Connection" w="100%" h={{ base: '300px', md: '500px' }} objectFit="cover" borderRadius={"lg"} borderColor={borderColor} borderWidth={2}  />

      {/* Container for Child Components */}
      <VStack spacing={5} w="full" align="stretch">
        {/* Description Section */}
      <Box bg={cardBg} p={5} shadow="md" borderRadius="lg" textAlign="center" borderColor={borderColor} borderWidth={2}>
          <Heading color={textColor}>Ride Connection Applications</Heading>
          <Text color={textColor}>Please select the appropriate application for your department.</Text>
        </Box>

        {/* Applications Grid */}
        <Box bg={cardBg} p={5}  shadow="md" borderRadius="lg" borderColor={borderColor} borderWidth={2}>
        <Grid templateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap={1} justifyContent="center" justifyItems={"center"}>
            {cardInfo.map((card, index) => (
              <CustomCard
                key={index}
                appName={card.appName}
                description={card.description}
                onButtonClick={() => navigate(card.redirectTo)}
                bg={textColor}
                textColor={cardBg}
              />
            ))}
          </Grid>
        </Box>
      </VStack>
    </Center>
  );
};

export default HomeView;
