import React from 'react';
import { Box, Button, Spinner, Stat, StatLabel, StatNumber, Badge } from '@chakra-ui/react';
import DownloadCSV from '../ServiceFunctions/DownloadCSV';
import getBadgeColor from '../ServiceFunctions/getBadgeColor';
import useDistributionCleanup from '../Hooks/useDistributionCleanup';

const DistributionCleanup = ({cacheKey}) => {
    const { data, isLoading, error, handleDistributionCleanup } = useDistributionCleanup(cacheKey);

    return (
        <Box mt="auto">
          <Box textAlign="right" mb={4}>
            <Button onClick={handleDistributionCleanup} isLoading={isLoading} disabled={isLoading}>
              Clean Up Distribution Dates
            </Button>
          </Box>
    
          {isLoading && <Spinner m={3} />}
    
          {!isLoading && error && (
            <Box mt={3}>
              <Badge colorScheme="red" px={2} py={1}>
                Error: {error.message || 'Failed to fetch data'}
              </Badge>
            </Box>
          )}
    
          {!isLoading && data && (
            <Box mt={4}>
              <Badge colorScheme={getBadgeColor('success')} px={2} py={1}>
                Message: {data.status || 'Success'}
              </Badge>
              <Stat>
                <StatLabel>Trip Count</StatLabel>
                <StatNumber>{data.data["Trip Count"]}</StatNumber>
              </Stat>
              <Stat mt={2}>
                <StatLabel>Member Count</StatLabel>
                <StatNumber>{data.data["Member Count"]}</StatNumber>
              </Stat>
              <Box mt={4}>
                <DownloadCSV data={data.data["filtered_data"]} filename="Clean_Distribution.csv" />
              </Box>
            </Box>
          )}
        </Box>
      );
};

export default DistributionCleanup;
