// config/endpoints.js

const endpoints = {
    filterProviders: '/filter-providers',
    filterCancel: '/filter-cancel',
    filterDuplicates: '/filter-duplicates',
    isoInvestigate: '/iso-investigate',
    isoSpecialStatus: '/iso-special-status',
    csvUpload: '/csv-upload',
    distributionCleanup: '/distribution-cleanup',
    specialPurpose: '/iso-special-purpose',
    filterPayable: '/iso-payable',
    combinedAnalysis: '/combined-analysis',
    fetchAndStore: '/fetch-and-store-reports/',
    singleProvider: '/get-reports/',
    allReports: '/get-all-grouped-reports/',
    providerList: '/get-provider-list/',
    uploadPdfReport: '/upload-pdf-report/',
    uploadProviders: '/upload-providers/',
    bulkAddProviders: '/bulk-add-providers/',
    updateProvider: '/update-provider/',
    getReportsAndUpload: '/get-reports-and-upload/',
    generateAndStoreAllReports: '/generate-and-store-all-reports/',
    downloadReport: '/download-report/',
    downloadAllReports: '/download-all-reports/',
    listReports: '/list-reports/',
  };
  
  export default endpoints;
  