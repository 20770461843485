import React from 'react';
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import useDynamicTable from '../Hooks/useDynamicTable';

const DynamicTable = ({ initialData }) => {
  const { rows, columns, setRows } = useDynamicTable(initialData);
//   const tcolumns = [
//     { key: 'id', name: 'ID', editable: true },
//     { key: 'title', name: 'Title', editable: true }
//   ];
//   const trows = [
//     { id: 1, title: 'Row 1' },
//     { id: 2, title: 'Row 2' },
//     { id: 3, title: 'Row 3' }
//   ];
  const handleRowsChange = (updatedRows, { fromRow, toRow, updated }) => {
    const newRows = rows.slice();
    for (let i = fromRow; i <= toRow; i++) {
      newRows[i] = { ...newRows[i], ...updated };
    }
    setRows(newRows);
  };

  return (
    initialData && initialData.length > 0 && (
      <DataGrid
        columns={columns}
        rows={rows}
        onRowsChange={handleRowsChange}
      />
    )
  );
};

export default DynamicTable;
