import React, { useState } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Avatar,
  Flex,
  Text,
  Box
} from '@chakra-ui/react';
import ProfileOptionsDrawer from './ProfileOptionsDrawer'; // Your Profile Options component
import defProfile from './assets/default-user-pic.png'

const UserProfile = ({ userInfo }) => {
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);

  const toggleProfileDrawer = () => {
    setIsProfileDrawerOpen(!isProfileDrawerOpen);
  };

  if (!userInfo) {
    return <Text>Loading...</Text>;
  }

  return (
    <>
      <Flex alignItems="center" cursor="pointer" onClick={toggleProfileDrawer}>
        {userInfo.name && <Text mr={2}>{userInfo.name}</Text>}{/* Name to the left */}
        {userInfo.avatar ? (
          <Box>
            <Avatar name={userInfo.name} src={userInfo.avatar} />
          </Box>
        ) : (
          <Box>
            <Avatar name="Default" src={defProfile} />
          </Box>
        )}
      </Flex>

      <Drawer placement="right" onClose={toggleProfileDrawer} isOpen={isProfileDrawerOpen} size="xs">
        <DrawerOverlay>
          <DrawerContent borderRadius="lg" style={{ height: '25%', alignSelf: 'flex-start' }}>
            <DrawerCloseButton />
            <DrawerHeader fontSize="xl">Profile Options</DrawerHeader>
            <DrawerBody>
              <ProfileOptionsDrawer />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default UserProfile;