import React, { useState } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import NavigationDrawer from './NavigationDrawer';
import RCLogo from './assets/RideConLogo.png';
import UserProfile from './UserProfile';
import { useUser } from '../../../Context/User';
import { Link } from 'react-router-dom';

const MainLayout = ({ children }) => {
  const { isLoggedIn, userInfo } = useUser();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const customGrayColor = useColorModeValue('white', 'customGray.700');
  const wrapperBg = useColorModeValue('white', 'customGray.700');
  const boxMargin = '10px'; // Adjust this value as needed
  //const boxShadow = '0 0 15px 5px rgba(255, 255, 255, 0.6)';
  const borderColor = useColorModeValue('customGray.700', 'customGray.100');

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Flex direction="column" minH="100vh">
      {/*Header */}
      <Box
        bg={customGrayColor}
        color={borderColor}
        borderBottomColor={borderColor}
        borderBottomWidth={1}
        p={{ base: 2, md: 3 }} 
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        boxShadow="0px 4px 6px rgba(0, 0, 0, 0.5)"
      >
        <Flex alignItems="center">
            {isLoggedIn && (
          <IconButton
            aria-label="Open Drawer"
            icon={<HamburgerIcon />}
            onClick={toggleDrawer}
            size="sm"
            colorScheme="customGreen"
            mr={5}
          />
            )}
            <Link to="/">
          <Image src={RCLogo} alt="RC Logo" boxSize="50px" />
          </Link>
        </Flex>
        {isLoggedIn && <UserProfile userInfo={userInfo} />}
      </Box>

      {/* Enable scrolling for the content */}
      <Box
        flex="1"
        bgGradient='linear(to-b, customGreen.500, customYellow.500 99%)'
        overflowY="auto"
        >
          <Flex justify="center" align="center" flex={1}>
          <Box
            w={{ base: "full", md: "99%" }} // Adjust the width here
            mx={boxMargin}
            my={boxMargin}
            p={4}
            bg={wrapperBg}
            borderColor={borderColor}
            borderWidth={1}
            borderRadius="lg"
          >
  {children}
  </Box>
  </Flex>
</Box>

      <Drawer placement="left" onClose={toggleDrawer} isOpen={isDrawerOpen} size="xs">
        <DrawerOverlay>
          <DrawerContent borderRadius="lg" style={{ height: '75%', alignSelf: 'flex-start' }}>
            <DrawerCloseButton />
            <DrawerHeader fontSize="xl">Navigation</DrawerHeader>
            <DrawerBody>
              <NavigationDrawer />
              <Flex alignItems="center" justifyContent="space-between" mt="1rem">
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      {/* Footer */}
      <Box
  bg={customGrayColor}
  color={borderColor}
  borderTopColor={borderColor}
  borderTopWidth={1}
  p={{ base: 2, md: 3 }}  // Adjusted padding for responsiveness
  textAlign="center"
  boxShadow="0px -4px 6px rgba(0, 0, 0, 0.1)"  // Changed shadow direction
>
  &copy; {new Date().getFullYear()} RideConnection NEMT App. All rights reserved.
  <br />
  <a >For support email </a>
  <a href="mailto:support@rideconnection.org">support@rideconnection.org</a>
</Box>
    </Flex>
  );
};

export default MainLayout;
