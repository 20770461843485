import React, { useRef } from 'react';
import {  useDisclosure, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import { useLazyGenerateAndStoreAllReportsQuery } from '../../../redux/providerReportsApi';
import StyledButtonBase from '../../../GlobalComponents/StyledButtonBase';

const GenerateAndStoreAllReports = () => {
    const [trigger, { isLoading, error, data }] = useLazyGenerateAndStoreAllReportsQuery();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();

    const handleGenerate = () => {
        trigger();
        onClose();
    };

    return (
        <>
            <StyledButtonBase onClick={onOpen} >Generate and Store All Reports</StyledButtonBase>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Generate and Store All Reports
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            This will generate a PDF for every provider and store it. Are you sure?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <StyledButtonBase ref={cancelRef} onClick={onClose}>
                                Cancel
                            </StyledButtonBase>
                            <StyledButtonBase colorScheme="red" onClick={handleGenerate} ml={3} isLoading={isLoading}>
                                Yes, Generate
                            </StyledButtonBase>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            {error && <p style={{ color: 'red' }}>Error: {error.message}</p>}
            {data && <p>Reports generated and stored successfully.</p>}
        </>
    );
};

export default GenerateAndStoreAllReports;
