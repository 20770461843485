//hooks/useFilterDuplicates.js
import useApiData from './getApiData';
import { API_ENDPOINTS_NEMT_PROVIDER } from '../../../configs/envConfig';

const useFilterDuplicates = (cacheKey) => {
    const filterDuplicatesUrl = `${API_ENDPOINTS_NEMT_PROVIDER.filterDuplicates}`;
    const { data, isLoading, error, fetchData } = useApiData(filterDuplicatesUrl);

    const handleFilterDuplicates = () => {
      fetchData(cacheKey);
    };

    return {data, isLoading, error, handleFilterDuplicates};
};

export default useFilterDuplicates;


