// src/views/ProviderReports/Components/FetchAndStoreReports.js
import React from 'react';
import { VStack, Flex, Spinner, Badge } from '@chakra-ui/react';
import { useFetchAndStoreReportsQuery } from '../../../redux/providerReportsApi';
import StyledButtonBase from '../../../GlobalComponents/StyledButtonBase';

const FetchAndStoreReports = () => {
  const { data, error, isLoading } = useFetchAndStoreReportsQuery();

  return (
    <Flex justify="end">
      <VStack spacing={4}>
        {isLoading && <Spinner />}
        {error && <Badge colorScheme="red">Error: {error.message}</Badge>}
        {data && !isLoading && <Badge colorScheme="green">Data Fetched Successfully</Badge>}
        {!isLoading && (
          <StyledButtonBase>Pull Data</StyledButtonBase>
        )}
      </VStack>
    </Flex>
  );
};

export default FetchAndStoreReports;
