import React from 'react';
import { Navigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';


const ProtectedRoute = ({ children, canAccess }) => {
    const toast = useToast();
    if (!canAccess) {
        // Show toast message
        toast({
            title: "Access Denied",
            description: "You don't have permission to access this page.",
            status: "error",
            duration: 9000,
            isClosable: true,
        });
        // Redirect User 
        return <Navigate to='/home' replace />;
    }
    return children;
};

export default ProtectedRoute;