import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';

const StyledBoxBase = ({ children, ...props }) => {
  const cardBg = useColorModeValue('white', 'customGray.700');
  const borderColor = useColorModeValue('customGray.700', 'white');

  return (
    <Box
      bg={cardBg}
      p={1}
      shadow="md"
      borderRadius="lg"
      textAlign="center"
      borderColor={borderColor}
      borderWidth={2}
      {...props} // Spread additional props
    >
      {children}
    </Box>
  );
};

export default StyledBoxBase;
