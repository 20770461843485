import React, { useState } from 'react';
import { VStack, Flex, Spinner, Badge, Input,  Box, Text } from '@chakra-ui/react';
import { useLazyDownloadReportQuery } from '../../../redux/providerReportsApi';
import StyledButtonBase from '../../../GlobalComponents/StyledButtonBase';

const DownloadReport = () => {
    const [reportName, setReportName] = useState('');
    const [trigger, { data, error, isLoading }] = useLazyDownloadReportQuery();

    const handleSubmit = (e) => {
        e.preventDefault();
        trigger(reportName);
    };

    const handleDownload = () => {
        if (data && data instanceof Blob) {
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${reportName}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    };

    const handlePreview = () => {
        if (data && data instanceof Blob) {
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
        }
    };

    return (
        <Flex justify="center" align="center" direction="column">
            <VStack spacing={4} as="form" onSubmit={handleSubmit}>
                <Input
                    placeholder="Enter Report Name"
                    value={reportName}
                    onChange={(e) => setReportName(e.target.value)}
                />
                <StyledButtonBase type="submit" colorScheme="blue" isLoading={isLoading}>
                    Fetch Report
                </StyledButtonBase>
                {isLoading && <Spinner />}
                {error && <Badge colorScheme="red">Error: {error.message}</Badge>}
                {data && (
                    <Box w="100%" p={4} borderWidth="1px" borderRadius="lg">
                        <Text fontWeight="bold">Report:</Text>
                        <Text>{reportName}</Text>
                        <StyledButtonBase onClick={handleDownload} colorScheme="green" mt={2}>
                            Download PDF
                        </StyledButtonBase>
                        <StyledButtonBase onClick={handlePreview} colorScheme="blue" mt={2}>
                            Preview PDF
                        </StyledButtonBase>
                    </Box>
                )}
            </VStack>
        </Flex>
    );
};

export default DownloadReport;
