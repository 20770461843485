import React from 'react';
import { Switch, useColorMode } from '@chakra-ui/react';

function DarkModeToggle() {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Switch
      size="sm" // You can adjust the size as needed
      isChecked={colorMode === 'dark'}
      onChange={toggleColorMode}
      colorScheme="customGrey" // Use your custom color scheme
    />
  );
}

export default DarkModeToggle;
