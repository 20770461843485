import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Home from '../views/Home';
import Login from '../views/Login';
import NemtProvider from '../views/NemtProvider';
import NemtProviderDashboard from '../views/NemtProvider/NemtProviderDashboard';
import { useUser } from '../Context/User';
import { Spinner, Center } from '@chakra-ui/react';
import Settings from '../views/Settings';
import ProtectedRoute from './components/ProtectedRoute';

import CredentialingDashboard from '../views/Credentialing/CredentialingDashboard';
import ProviderReportsView from '../views/Credentialing/ProviderReportsView'; // Correct import

const AppRouter = () => {
  const { isLoggedIn, userInfo, isLoading } = useUser();

  if (isLoading) {
    return (
      <Center height="100vh">
        <Spinner />
      </Center>
    );
  }

  // Check for 'NEMT_Provider' role and 'Admin' role in user's roles
  const isProvider = userInfo?.roles?.includes('NEMT');
  const isAdmin = userInfo?.roles?.includes('Admin'); // Add your admin role check
  const isCredential = userInfo?.roles?.includes('Credentialing'); 

  return (
    <Routes>
      {isLoggedIn ? (
        <>
          <Route path="/home" element={<Home />} />
          <Route path="/nemt-provider-dashboard" element={
            <ProtectedRoute canAccess={isProvider || isAdmin}>
              <NemtProviderDashboard />
            </ProtectedRoute>
          }/>
          <Route path="/nemt-provider" element={
            <ProtectedRoute canAccess={isProvider || isAdmin}>
              <NemtProvider />
            </ProtectedRoute>
          }/>
          <Route path="/provider-reports" element={
            <ProtectedRoute canAccess={isCredential || isAdmin}>
              <ProviderReportsView />
            </ProtectedRoute>
          }/>
          <Route path="/credentialing-dashboard" element={
            <ProtectedRoute canAccess={isCredential || isAdmin}>
              <CredentialingDashboard />
            </ProtectedRoute>
          }/>
          <Route path="/settings" element={<Settings />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </>
      ) : (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </>
      )}
    </Routes>
  );
};

export default AppRouter;